import {
    Text,
    Title,
    Container,
    SimpleGrid,
} from '@mantine/core';
import { ContactIconsList } from './ContactIcons';
import classes from './Contact.module.css';


export default function ContactUs() {

    return (
        <div className={classes.wrapper}>
            <Container>
                <SimpleGrid cols={{ base: 1, sm: 2, lg: 2 }}>
                <Container pl={0}>
                    <Title className={classes.title}>Contact us</Title>
                    <Text className={classes.description} mt="sm" mb={30}>
                        Feel free to contact us at any time, we are always ready to help you.
                    </Text>
                </Container>

                    <ContactIconsList />
                </SimpleGrid>
            </Container>
        </div>
    );
}