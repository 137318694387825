import {Text, SimpleGrid, Container, rem, useMantineTheme, Title, Center} from '@mantine/core';
import { IconMapPin, IconCertificate, IconUserCheck } from '@tabler/icons-react';
import classes from './FeaturesAsymmetrical.module.css';

interface FeatureProps extends React.ComponentPropsWithoutRef<'div'> {
    icon: React.FC<any>;
    title: string;
    description: string;
}

function Feature({ icon: Icon, title, description, className, ...others }: FeatureProps) {
    const theme = useMantineTheme();
    return (
        <div className={classes.feature} {...others}>
            <div className={classes.overlay} />

            <div className={classes.content}>
                <Icon style={{ width: rem(38), height: rem(38) }} className={classes.icon} color={theme.colors.yellow[9]} stroke={1.4} />
                <Text fw={700} fz="lg" mb="xs" mt={5} className={classes.title}>
                    {title}
                </Text>
                <Text c="dimmed" fz="sm">
                    {description}
                </Text>
            </div>
        </div>
    );
}

const DATA = [
    {
        icon: IconMapPin,
        title: 'Convenient location',
        description:
            'Location of the warehouse in the heart of Kurzeme region allows us to provide comprehensive truck delivery conditions for exports to Western European countries. The warehouse is located in short distance from major ports and Latvian border ensuring cost effective logistics for our customers.',
    },
    {
        icon: IconCertificate,
        title: 'High quality products',
        description:
            'All products sold comply to current quality regulations and has manufacturer`s certificate. Regular laboratory testing is done to ensure standard compliance of goods',
    },
    {
        icon: IconUserCheck,
        title: 'Personalized attitude',
        description:
            'Our clients matter to us. Our team of professionals put their best effort to provide cooperation fitted to our client`s needs.',
    },
];

export default function FeaturesAsymmetrical() {
    const items = DATA.map((item) => <Feature {...item} key={item.title} />);

    return (
        <Container mt={60} mb={80} size="lg">
            <Center><Title>We are here at your service</Title></Center>
            <SimpleGrid cols={{ base: 1, sm: 3 }} spacing={50}>
                {items}
            </SimpleGrid>
        </Container>
    );
}