import {Container, Title, Text, SimpleGrid, Grid, rem, Image, Center} from '@mantine/core';

const PRIMARY_COL_HEIGHT = rem(300);

export default function About() {
    const SECONDARY_COL_HEIGHT = `calc(${PRIMARY_COL_HEIGHT} / 2 - var(--mantine-spacing-md) / 2)`;

    return (
        <Container my="lg">
            <SimpleGrid cols={{base: 1, sm: 2}} spacing="md">
                <Center>
                <Container>
                    <Title order={1}>About our company</Title>
                    <Text size="xl" mt="sm" mb="lg">
                        SIA IC Trading is Latvian based company operating an excise warehouse in Dobele, 52 Uzvaras street. We specialize in diesel fuel and oil products trade to our clients in different European countries and Ukraine.
                    </Text>
                </Container>
                </Center>
                <Grid gutter="md">
                    <Grid.Col span={{ base: 12, md: 6}}>
                        <Image
                            height={SECONDARY_COL_HEIGHT}
                            src="grid_1.png"
                            radius="sm"
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6}}>
                        <Image
                            height={SECONDARY_COL_HEIGHT}
                            src="grid_2.png"
                            radius="sm"
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6}}>
                        <Image
                            height={SECONDARY_COL_HEIGHT}
                            src="grid_3.png"
                            radius="sm"
                        />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6}}>
                        <Image
                            height={SECONDARY_COL_HEIGHT}
                            src="grid_4.png"
                            radius="sm"
                        />
                    </Grid.Col>
                </Grid>
            </SimpleGrid>
        </Container>
    );
}
