
import { Title, Container, Overlay } from '@mantine/core';
import classes from './HeroImage.module.css';

export default function HeroImage() {
    return (
        <div className={classes.wrapper}>
            <Overlay color="#000" opacity={0.65} zIndex={1} />
            <div className={classes.inner}>
                <Container py={300}>
                <Title className={classes.title}>
                    Straightforward ways to meet our client`s needs
                </Title>
                </Container>
            </div>
        </div>
    );
}