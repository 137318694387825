import React from 'react';
import {useRef} from 'react';
import {Center, MantineProvider, Text} from '@mantine/core';
import '@mantine/core/styles.css';
import HeroContent from "./components/HeroContent";
import ContactUs from "./components/Contact";
import About from "./components/About";
import FeatureCards from "./components/FeatureCards";
import HeroImage from "./components/HeroImage";
import FeaturesAsymmetrical from "./components/FeaturesAsymmentrical";
import classes from './CopyrightWrapper.module.css';

function App() {
    const ContactUsSection = useRef<HTMLDivElement | null>(null);
    function scrollToContactUsSection() {
        ContactUsSection.current?.scrollIntoView({behavior: 'smooth'});
    }
    return (
        <MantineProvider>
            <HeroContent scrollToSection={scrollToContactUsSection}/>
            <About/>
            <FeatureCards/>
            <HeroImage/>
            <FeaturesAsymmetrical/>
            <div ref={ContactUsSection}>
                <ContactUs/>
            </div>
            <div className={classes.wrapper}>
                <Center>
                    <Text c="white">©IC Trading 2024, all rights reserved.</Text>
                </Center>
            </div>

        </MantineProvider>
);
}

export default App;
