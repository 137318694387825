import {
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme,
} from '@mantine/core';
import { IconDropCircle, IconBackhoe, IconFlame } from '@tabler/icons-react';
import classes from './FeatureCards.module.css';

const DATA = [
    {
        title: 'General Use Diesel',
        description:
            'We are one of the leading Latvian companies that provide general use diesel fuel for wholesale to Latvian, European and Ukrainian customers. Product availability is always supervised according to current weather conditions and legal regulations. All products sold are tested and certified.',
        icon: IconDropCircle,
    },
    {
        title: 'Anti-Adhesive General Purpose Agent REMOLAG ULV10',
        description:
            'An unique product that has been developed and produced exclusively by our warehouse. Agent is used most often in concrete construction industry to protect molds from sticking to other materials.',
        icon: IconBackhoe,
    },
    {
        title: 'Diesel For Heating',
        description:
            'To keep up with tendencies in energy sector and meet local demand for cost effective heating alternatives, since year 2022 we also provide opportunity to purchase colored diesel for heating that is sold with reduced excise rate according State Revenue Service`s regulations.',
        icon: IconFlame,
    },
];

export default function FeatureCards() {
    const theme = useMantineTheme();
    const features = DATA.map((feature) => (

        <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl" withBorder={true}>
            <feature.icon
                style={{ width: rem(50), height: rem(50) }}
                stroke={1.4}
                color={theme.colors.yellow[9]}
            />
            <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
                {feature.title}
            </Text>
            <Text fz="sm" c="dimmed" mt="sm">
                {feature.description}
            </Text>
        </Card>
    ));

    return (
        <div className={classes.wrapper}>
        <Container size="lg" py="xl">
            <Title ta="center" mt="sm" c="white">
                Our products
            </Title>

            <Text size="xl" ta="center" mt="md" c="white">
                We work hard every day to provide our clients with high quality oil products at competitive price
            </Text>

            <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
                {features}
            </SimpleGrid>
        </Container>
        </div>
    );
}